import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI, ShopAPI } from '@/apis';
import { RewardCategoryModel } from '@/models/RewardCategoryModel';


export class ShopService {

  private static internal: ShopService = new ShopService(ShopAPI.instance);

  public static get instance(): ShopService {
    return ShopService.internal;
  }

  constructor(private api: AxiosInstance) { }


  private iEventID = -666;



}
