export class OrganizationEventResumeModel {
    public draft: number = 0;
    public published: number = 0;
    public archive: number = 0;
    public registeredPublished: number = 0;
    public requiredPublished: number = 0;
    public registeredAll: number = 0;
  public requiredAll: number = 0;
  public hoursRequired: number = 0;
  public hoursRegistered: number = 0;

    constructor({
        draft = 0,
        published = 0,
        archive = 0,
        registeredPublished = 0,
        requiredPublished = 0,
        registeredAll = 0,
        requiredAll = 0,
        hoursRequired = 0,
        hoursRegistered = 0,
       }: {
        draft?: number,
        published?: number,
        archive?: number,
        registeredPublished?: number,
        requiredPublished?: number,
        registeredAll?: number,
        requiredAll?: number,
        hoursRequired?: number,
        hoursRegistered?: number,
       } = {}) {
        this.draft = draft;
        this.published = published;
        this.archive = archive;
        this.registeredPublished = registeredPublished;
        this.requiredPublished = requiredPublished;
        this.registeredAll = registeredAll;
        this.requiredAll = requiredAll;
        this.hoursRegistered = hoursRegistered;
        this.hoursRequired = hoursRequired;
      }

      public toJSON(): any {
        return {
            draft: this.draft,
            published: this.published,
            archive: this.archive,
            registeredPublished: this.registeredPublished,
            requiredPublished: this.requiredPublished,
            registeredAll: this.registeredAll,
            requiredAll: this.requiredAll,
            hoursRegistered: this.hoursRegistered,
            hoursRequired: this.hoursRequired,
        };
      }
}
