import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI } from '@/apis';
import { VolunteerModel } from '@/models/VolunteerModel';
import { AuthenticationService } from '@/services';
import { ParticipationModel } from '@/models/ParticipationModel';
import { CommunicationHistory } from '@/models/CommunicationHistory';


export class EmailService {

  public static get instance(): EmailService {
    return EmailService.internal;
  }

  private static internal: EmailService = new EmailService(OrganizerAPI.instance);

  constructor(private api: AxiosInstance) { }

  public async sendBlankTestEmail(eventId: string): Promise<void> {
    const newAuthUser = AuthenticationService.instance.authenticatedUser;
    let config = {};
    if (newAuthUser !== null) {
      config = {
        headers: {
          Authorization: `Token ${newAuthUser.token}`,
        },
      };
    }

    return this.api.post('/events/' + eventId + '/test_send_mail/', {
    }, config).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      return error;
    });
  }

  public sendRegistrationResumeEmail(eventId: string, part: ParticipationModel): Promise<void> {
    return this.api.post('/events/' + eventId + '/send_resume_mail/', {
      participation: part,
    }).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      return error;
    });
  }

  public getCommunicationHistory(token: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/by-token/${token}/communications/`,
    ).then((response: AxiosResponse<any>) => {
      return response;
    });
  }

  public getCommunicationHistoryDetail(token: string, commId: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/by-token/${token}/communications/${commId}`,
    ).then((response: AxiosResponse<any>) => {
      return response;
    });
  }


  public async sendEmail(
    eventId: string,
    daysArray: string[],
    positionsArray: number[] | null,
    subDateStart: string | null,
    mailBody: string,
    onlyForOwner: boolean,
    status: string[] | null,
  ): Promise<{ [key: string]: any }> {

    const newAuthUser = AuthenticationService.instance.authenticatedUser;
    let config = {};

    if (newAuthUser !== null) {
      config = {
        headers: {
          Authorization: `Token ${newAuthUser.token}`,
        },
      };
    }

    const body: any = {
      emailBody: mailBody,
      onlyOwner: onlyForOwner,
      days: daysArray,
      positions: positionsArray ? positionsArray : [],
      subscriptionDateStart: subDateStart,
    };

    if (status) {
      body.participationValidationStatuses = status;
    }


    return this.api.post('/events/' + eventId + '/send_mail/', body, config).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      return error;
    });
  }

  public async sendEmailToCampaign(
    cmpId: string, mailBody: string, onlyForOwner: boolean): Promise<{ [key: string]: any }> {

    const newAuthUser = AuthenticationService.instance.authenticatedUser;
    let config = {};

    if (newAuthUser !== null) {
      config = {
        headers: {
          Authorization: `Token ${newAuthUser.token}`,
        },
      };
    }

    return this.api.post('/campaigns/' + cmpId + '/send_mail/', {
      emailBody: mailBody,
      onlyOwner: onlyForOwner,
    }, config).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      return error;
    });
  }

  public async sendEmailToVols(volArray: number[],
    mailBody: string,
    replyTo: string): Promise<{ [key: string]: any }> {

    const newAuthUser = AuthenticationService.instance.authenticatedUser;
    let config = {};
    if (newAuthUser !== null) {
      config = {
        headers: {
          Authorization: `Token ${newAuthUser.token}`,
        },
      };
    }

    return this.api.post('/volunteers/send_mail_to_vol/', {
      emailBody: mailBody,
      vols: volArray,
      replyToEmail: replyTo,
    }, config).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      return error;
    });
  }



  public retreiveCommunicationHistory(organizationId: number): Promise<CommunicationHistory[]> {
    return this.api.get<CommunicationHistory[]>(`/organization/${organizationId}/communication/`, {
    }).then((response: AxiosResponse<CommunicationHistory[]>) => {
      return response.data.map((d: any): CommunicationHistory => new CommunicationHistory(d));
    });
  }

  public previewCampaignEmail(campaignId: string, emailBody: string): Promise<any> {
    return this.api.post(
      `/campaigns/${campaignId}/preview_mail/`,
      { body: emailBody },
    ).then((response: AxiosResponse<any>) => {
      return response;
    });
  }

  public previewEventEmail(eventId: number, emailBody: string): Promise<any> {
    return this.api.post(
      `/events/${eventId}/preview_mail/`,
      { body: emailBody },
    ).then((response: AxiosResponse<any>) => {
      return response;
    });
  }
}
