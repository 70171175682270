import { AxiosRequestConfig } from 'axios';
import { AuthenticationService } from '@/services';
import Vue from 'vue';

export const AUTHENTICATION_USE_INTERCEPTOR = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const newAuthUser = AuthenticationService.instance.authenticatedUser;

  if (newAuthUser !== null) {
    const csrfToken = Vue.$cookies.get('_csrftoken');

    if (csrfToken !== null) {
      config.headers['X-CSRFToken'] = csrfToken;

      // The CSRF token is stored in a cookie so we need CSRF-protected requests
      // to be sent with cookies so the server can compare them
      config.withCredentials = true;
    }
  }

  return config;
};

export const AUTHENTICATION_REJECT_INTERCEPTOR = (error: any): any => Promise.reject(error);
