
import { EventModel } from '@/models/EventModel';
import { TimeSlotModel } from '@/models/TimeSlotModel';
import { ParticipationResumeModel } from './ParticipationResumeModel';

export class EventWithResumeModel extends EventModel {
  // class body
  public participationsResume: ParticipationResumeModel[] = [];

  public populateSlots(data: any) {
    this.participationsResume = data.map((d: any): ParticipationResumeModel => {
      let part: ParticipationResumeModel;
      if (d instanceof ParticipationResumeModel) {
        part = d;
      } else {
        d.eventTimeZone = this.timeZone;
        part = new ParticipationResumeModel(d);
      }
      part.timeSlot.eventName = this.name;
      part.timeSlot.eventId = this.id;
      return part;
    });
  }

  public get soloValidatedParticipations() {
    return this.participationsResume.filter((p) => p.nbParticipants === 1 && p.validationStatus === 6);
  }
  public get groupValidatedParticipations() {
    return this.participationsResume.filter((p) => p.nbParticipants > 1 && p.validationStatus === 6);
  }
}
