
export class CustomAnswerResumeModel {
  public id: number | null = null;
  public participation: number | null = null;
  public field: number | null = null;
  public fieldTitle: string;
  public fieldType: number;
  public answers: string[];
  public selectValues: string;

  constructor({
    id = null,
    participation = null,
    field = null,
    fieldTitle = '',
    fieldType = 666,
    answers = [],
    selectValues = '',
  }: {
    id?: number | null,
    participation?: number | null,
    field?: number | null,
    fieldTitle?: string,
    fieldType?: number,
    answers?: string[];
    selectValues?: string,
  } = {}) {
    this.id = id;
    this.participation = participation;
    this.field = field;
    this.fieldTitle = fieldTitle;
    this.fieldType = fieldType;
    this.answers = answers;
    this.selectValues = selectValues;
  }

  public toJSON(): any {
    return {
      id: this.id,
      participation: this.participation,
      field: this.field,
      fieldTitle: this.fieldTitle,
      fieldType: this.fieldType,
      answer: this.answers,
      selectValues: this.selectValues,
    };
  }
}

