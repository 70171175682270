









import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { AuthenticationService } from '@/services';
import { PermissionLib } from './services/PermissionLib';

@Component({})
export default class App extends Vue {

  @Provide('authenticatedUser') private authenticatedUser = AuthenticationService.instance.authenticatedUser;
  @Provide('permissionLib') private permissionLib = new PermissionLib();

}
