import Vue from 'vue';
import Router, { RawLocation, Route, RouteRecord } from 'vue-router';
import { AuthenticationService } from '@/services';
import { UserModel } from '@/models/UserModel';
import i18n from '@/i18n';
import { v4404rUrl, v4Url, v4VolunteerUrl } from '@/_nav';
import { OrganizationModel } from './models/OrganizationModel';
import { PermissionLib } from './services/PermissionLib';

// Containers
const ADMINCONTAINER = () => import('./containers/AdminContainer.vue');
const LOGINCONTAINER = () => import('./containers/LoginContainer.vue');
const PUBLICCONTAINER = () => import('./containers/PublicContainer.vue');

// Views
const DASHBOARD = () => import('./views/dashboard/Dashboard.vue');

// User specific
const PROFILEPAGEUSER = () => import('./components/user/ProfileUser.vue');
const PROFILEPAGEORG = () => import('./components/user/ProfileOrg.vue');
const SUBSCRIPTIONS = () => import('./views/user/Subscriptions.vue');

// HOME
const CONTACT = () => import('./views/public/AdminContact.vue');
const DOC = () => import('./views/public/documentation/DocMain.vue');
const SOON = () => import('./views/public/documentation/Soon.vue');
const WHATSNEW = () => import('@/views/public/ReleaseNotes/ReleaseNoteMain.vue');
const VLOG = () => import('@/views/public/vlog/VlogMain.vue');

// EVENT
const PUBLIC_EVENT = () => import('./views/public/PublicEventDetail.vue');
const FFG_PUBLIC_EVENT = () => import('./views/public/FFGPublicEventDetail.vue');
const FFG_MANAGER_EVENT = () => import('./views/public/FFGPublicManagerDetail.vue');
const PUBLIC_CAL_EVENT = () => import('./views/public/PublicEventCALDetail.vue');
const PUBLIC_GLOBAL_CAL = () => import('./views/public/PublicGlobalCal.vue');
const PUBLIC_EVENT_FRAME = () => import('./views/public/PublicEventDetailFrame.vue');
const PUBLIC_BOOKING_EMBED = () => import('./views/public/PublicBookingEmbed.vue');
const PUBLIC_CAMPAIGN = () => import('./views/public/PublicCampaignDetail.vue');
const EVENTLIST = () => import('./views/EventList.vue');
const EVENTARCHIVELIST = () => import('./views/EventArchiveList.vue');
const EVENTDETAIL = () => import('./views/EventDetail.vue');
const EVENTPLAN = () => import('./views/EventPlanning.vue');
const EVENTCLONING = () => import('./views/EventCloning.vue');
const EVENTVOLUNTEERS = () => import('./views/EventVolunteersDetail.vue');
const EVENTVOLUNTEERSASSIGN = () => import('./views/assignments/EventVolunteersAssign.vue');
const CALENDAR = () => import('@/views/Calendar.vue');
const MANAGER_EVENT = () => import('@/views/public/PublicManagerDetail.vue');



// CAMPAIGN
const CMPLIST = () => import('@/views/campaign/CampaignList.vue');
const CMPDETAIL = () => import('@/views/campaign/CampaignDetail.vue');
const CMPDEBUG = () => import('@/views/campaign/CampaignDebug.vue');
const CMPVOLUNTEERS = () => import('./views/campaign/CampaignVolunteersAdmin.vue');

// MANAGER
const MANAGERLIST = () => import('@/views/managers/ManagerList.vue');
const MANAGERDETAIL = () => import('@/views/managers/ManagerDetail.vue');

// ACTIONS
const PRINT = () => import('./views/actions/ActionPrint.vue');
const PRINTPPB = () => import('./views/actions/ActionPrintPPB.vue');
const PRINTTSHIRT = () => import('./views/actions/ActionPrintTShirt.vue');
const PRINTBON = () => import('./views/actions/ActionPrintBon.vue');

// UTILS
const DOWN = () => import('./views/public/Down.vue');
const NOSESSION = () => import('@/views/pages/NoSession.vue');

const ADMINTOOLS = () => import('@/views/admin/tools.vue');

// RESET PWD
const LOGIN = () => import('@/views/pages/Login.vue');
const REGISTER = () => import('@/views/pages/Register.vue');
const LOGOUT = () => import('@/views/pages/Logout.vue');
const VERIFY = () => import('@/views/pages/VerifyEmail.vue');
const RESETPWD = () => import('@/views/pages/PasswordReset.vue');
const RESETPWDCONFIRM = () => import('@/views/pages/PasswordResetConfirm.vue');

const MANAGERPAGE = () => import('@/views/public/joj/ManagerMain.vue');
const VOLUNTEER_LOGIN = () => import('@/views/public/Portail/VolLoginPage.vue');
const VOLUNTEER_LOGIN_WITH_TOKEN = () => import('@/views/public/Portail/VolPortailMain.vue');
const VOLUNTEER_CMP_DETAIL = () => import('@/views/public/VolunteerCampaignDetail.vue');

const PUBLIC_HOME = () => import('@/views/public/org/PublicHome.vue');
const PUBLIC_CONTACT = () => import('@/views/public/org/PublicContact.vue');

const TEMPDEV = () => import('@/components/event-detail/EventDetailScheduling.vue')

const SHOPCONFIG = () => import('@/views/shop/ShopConfig.vue')

Vue.use(Router);

// const MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE === 'True';
const MAINTENANCE_MODE = false;


const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/:orgName/public_manager/:orgID',
      name: 'public_manager_event_detail_uuid',
      meta: { requiresAuth: false, label: 'EHRO - Espace manager' },
      component: MANAGERPAGE,
      props: true,
    },
    {
      path: '/app/:orgName/public_manager/:orgID',
      name: 'app-public_manager_event_detail_uuid',
      meta: { requiresAuth: false, label: 'EHRO - Espace manager' },
      component: MANAGERPAGE,
      props: true,
    },


    {
      path: '/404',
      meta: { requiresAuth: false },
      name: '404',
      beforeEnter(to, from, next) {
        window.location.href = v4404rUrl();
      },
    },
    {
      path: '/app/404',
      meta: { requiresAuth: false },
      name: 'app-404',
      beforeEnter(to, from, next) {
        window.location.href = v4404rUrl();
      },
    },

    {
      path: '/500',
      name: '500',
      meta: { requiresAuth: false },
      beforeEnter(to, from, next) {
        window.location.href = v4404rUrl();
      },
    },
    {
      path: '/app/500',
      name: 'app-500',
      meta: { requiresAuth: false },
      beforeEnter(to, from, next) {
        window.location.href = v4404rUrl();
      },
    },


    // REDIRECT TO PUBLIC SITE
    {
      path: '/home',
      name: 'public-home',
      meta: { requiresAuth: false },
      beforeEnter() {
        location.href = `${process.env.VUE_APP_PUBLIC_SITE}home`;
      },
    },
    {
      path: '/app/home',
      name: 'app-public-home',
      meta: { requiresAuth: false },
      beforeEnter() {
        location.href = `${process.env.VUE_APP_PUBLIC_SITE}home`;
      },
    },



    // ADMIN PART
    {
      path: '/',
      redirect: '/dashboard',
      name: 'EHRO',
      meta: { requiresAuth: true, label: 'EHRO' },
      component: ADMINCONTAINER,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: { label: i18n.t('dashboard') },
          component: DASHBOARD,
        },
        {
          path: 'Profile-user',
          name: 'profile-user',
          meta: { label: 'Profil' },
          component: PROFILEPAGEUSER,
          props: true,
        },
        {
          path: 'Profile-org',
          name: 'profile-org',
          meta: { label: i18n.tc('organization', 1) },
          component: PROFILEPAGEORG,
          props: true,
        },
        {
          path: 'Subscriptions',
          name: 'subscriptions',
          meta: { label: i18n.tc('paiement', 2) },
          component: SUBSCRIPTIONS,
          props: true,
        },
        {
          path: 'events',
          redirect: 'events/list',
          name: 'Events',
          meta: { label: 'Events' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'event-list',
              meta: { label: 'List' },
              component: EVENTLIST,
            },
            {
              path: 'archive',
              name: 'event-archive',
              meta: { label: 'Archives' },
              component: EVENTARCHIVELIST,
            },
            {
              path: 'new',
              name: 'event-detail-new',
              meta: { label: 'Create' },
              component: EVENTDETAIL,
            },
            {
              path: 'calendar',
              name: 'calendar',
              component: CALENDAR,
              meta: { label: i18n.t('calendar') },
            },
            {
              path: 'cloning/:eventId',
              name: 'event-cloning',
              meta: { label: i18n.t('path_cloning') },
              component: EVENTCLONING,
              props: true,
            },
            {
              path: ':eventId',
              name: 'event-detail-edit',
              meta: { label: 'Edit' },
              component: EVENTDETAIL,
              props: true,
            },
            {
              path: ':eventId/plannig',
              name: 'event-detail-plan',
              meta: { label: i18n.t('event_action_plan') },
              component: EVENTPLAN,
              props: true,
            },

            {
              path: 'dev/:eventId',
              name: 'event-dev',
              meta: { label: 'DEV' },
              component: TEMPDEV,
              props: true,
            },
            {
              path: 'assign/:eventId',
              name: 'event-volunteers-affectation',
              component: EVENTVOLUNTEERSASSIGN,
              props: true,
              meta: { label: 'Affectation des inscriptions' },
            },
            {
              path: 'volunteers/:eventId',
              name: 'event-volunteers-detail',
              component: EVENTVOLUNTEERS,
              props: true,
              meta: { label: 'Gestion des inscriptions' },
            },

          ],
        },
        {
          path: 'campaigns',
          redirect: 'campaigns/list',
          name: 'Campaign',
          meta: { label: 'Campaign' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'new',
              name: 'campaign-detail-new',
              meta: { label: 'Create' },
              component: CMPDETAIL,
            },
            {
              path: 'list',
              name: 'campaign-list',
              meta: { label: 'List' },
              component: CMPLIST,
            },
            {
              path: 'debug',
              name: 'campaign-debug',
              meta: { label: 'Debug' },
              component: CMPDEBUG,
            },
            {
              path: ':cmpId',
              name: 'campaign-detail-edit',
              meta: { label: 'Edit' },
              component: CMPDETAIL,
              props: true,
            },
            {
              path: 'volunteers/:cmpId',
              name: 'campaign-volunteers-detail',
              component: CMPVOLUNTEERS,
              props: true,
              meta: { label: 'Gestion des inscriptions' },
            },
            {
              path: 'volunteer/:volunteerId',
              name: 'campaign-volunteer-profile',
              meta: { label: i18n.t('profile') },
              props: true,
              beforeEnter(to, from, next) {
                window.location.href = v4VolunteerUrl(Number(to.params.volunteerId));
              },
            },
          ],
        },
        {
          path: 'volunteer',
          redirect: 'volunteer/list',
          name: 'Volunteers',
          meta: { label: 'Volunteers' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'volunteer-list',
              meta: { label: 'List' },
              beforeEnter(to, from, next) {
                window.location.href = v4Url('/volunteers/');
              },
            },
            {
              path: 'category',
              name: 'volunteer-category',
              meta: { label: 'Mes categories' },
              beforeEnter(to, from, next) {
                window.location.href = v4Url('/categories/');
              },
            },
            {
              path: ':volunteerId',
              name: 'volunteer-profile',
              meta: { label: i18n.t('profile') },
              props: true,
              beforeEnter(to, from, next) {
                window.location.href = v4VolunteerUrl(Number(to.params.volunteerId));
              },
            },
          ],
        },
        {
          path: 'manager',
          redirect: 'manager/list',
          name: 'Managers',
          meta: { label: 'Managers' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'Managers-list',
              meta: { label: 'Managers' },
              component: MANAGERLIST,
            },
          ],
        },
        {
          path: 'shop',
          redirect: 'Shop/Config',
          name: 'Shop',
          meta: { label: 'Shop' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'config',
              name: 'shop-config',
              meta: { label: 'Config' },
              component: SHOPCONFIG,
            },
          ],
        },
        {
          path: 'action',
          redirect: '/action/communication',
          name: 'Action',
          meta: { label: 'Action' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'print',
              name: 'Print',
              meta: { label: 'Impression / Plannings' },
              component: PRINT,
            },
            {
              path: 'print-ppb',
              name: 'Print-ppb',
              meta: { label: 'Impression / Plannings par bénévole' },
              component: PRINTPPB,
            },
            {
              path: 'print-tshirt',
              name: 'Print-tshirt',
              meta: { label: 'Impression / Listing des t-shirts' },
              component: PRINTTSHIRT,
            },
            {
              path: 'print-bon',
              name: 'Print-bon',
              meta: { label: 'Impression / Bons' },
              component: PRINTBON,
            },
          ],
        },
        {
          path: 'infos',
          redirect: '/infos/whatsnew',
          name: 'Infos',
          meta: { label: 'Infos' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'whatsnew',
              name: 'whatsnew',
              meta: { label: 'Quoi de neuf?' },
              component: WHATSNEW,
            },
            {
              path: 'contact',
              name: 'Contact',
              meta: { label: 'Contact' },
              component: CONTACT,
            },
            {
              path: 'doc',
              name: 'doc',
              meta: { label: 'Documentation' },
              component: DOC,
            },
            {
              path: 'vlog',
              name: 'vlog',
              meta: { label: 'Vlog' },
              component: VLOG,
            },
            {
              path: 'soon',
              name: 'soon',
              meta: { label: 'À venir' },
              component: SOON,
            },
          ],
        },
      ],
    },
    {
      path: '/app/',
      redirect: '/app/dashboard',
      name: 'app-EHRO',
      meta: { requiresAuth: true, label: 'EHRO' },
      component: ADMINCONTAINER,
      children: [
        {
          path: 'dashboard',
          name: 'app-dashboard',
          meta: { label: i18n.t('dashboard') },
          component: DASHBOARD,
        },
        {
          path: 'Profile-user',
          name: 'app-profile-user',
          meta: { label: 'Profil' },
          component: PROFILEPAGEUSER,
          props: true,
        },
        {
          path: 'Profile-org',
          name: 'app-profile-org',
          meta: { label: i18n.tc('organization', 1) },
          component: PROFILEPAGEORG,
          props: true,
        },
        {
          path: 'Subscriptions',
          name: 'app-subscriptions',
          meta: { label: i18n.tc('paiement', 2) },
          component: SUBSCRIPTIONS,
          props: true,
        },
        {
          path: 'events',
          redirect: 'events/list',
          name: 'app-events',
          meta: { label: 'Events' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'app-event-list',
              meta: { label: 'List' },
              component: EVENTLIST,
            },
            {
              path: 'archive',
              name: 'app-event-archive',
              meta: { label: 'Archives' },
              component: EVENTARCHIVELIST,
            },
            {
              path: 'new',
              name: 'app-event-detail-new',
              meta: { label: 'Create' },
              component: EVENTDETAIL,
            },
            {
              path: 'calendar',
              name: 'app-calendar',
              component: CALENDAR,
              meta: { label: i18n.t('calendar') },
            },
            {
              path: 'cloning/:eventId',
              name: 'app-event-cloning',
              meta: { label: i18n.t('path_cloning') },
              component: EVENTCLONING,
              props: true,
            },
            {
              path: ':eventId',
              name: 'app-event-detail-edit',
              meta: { label: 'Edit' },
              component: EVENTDETAIL,
              props: true,
            },
            {
              path: ':eventId/plannig',
              name: 'app-event-detail-plan',
              meta: { label: i18n.t('event_action_plan') },
              component: EVENTPLAN,
              props: true,
            },

            {
              path: 'dev/:eventId',
              name: 'app-event-dev',
              meta: { label: 'DEV' },
              component: TEMPDEV,
              props: true,
            },
            {
              path: 'assign/:eventId',
              name: 'app-event-volunteers-affectation',
              component: EVENTVOLUNTEERSASSIGN,
              props: true,
              meta: { label: 'Affectation des inscriptions' },
            },
            {
              path: 'volunteers/:eventId',
              name: 'app-event-volunteers-detail',
              component: EVENTVOLUNTEERS,
              props: true,
              meta: { label: 'Gestion des inscriptions' },
            },

          ],
        },
        {
          path: 'campaigns',
          redirect: 'campaigns/list',
          name: 'app-Campaign',
          meta: { label: 'Campaign' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'new',
              name: 'app-campaign-detail-new',
              meta: { label: 'Create' },
              component: CMPDETAIL,
            },
            {
              path: 'list',
              name: 'app-campaign-list',
              meta: { label: 'List' },
              component: CMPLIST,
            },
            {
              path: 'debug',
              name: 'app-campaign-debug',
              meta: { label: 'Debug' },
              component: CMPDEBUG,
            },
            {
              path: ':cmpId',
              name: 'app-campaign-detail-edit',
              meta: { label: 'Edit' },
              component: CMPDETAIL,
              props: true,
            },
            {
              path: 'volunteers/:cmpId',
              name: 'app-campaign-volunteers-detail',
              component: CMPVOLUNTEERS,
              props: true,
              meta: { label: 'Gestion des inscriptions' },
            },
            {
              path: 'volunteer/:volunteerId',
              name: 'app-campaign-volunteer-profile',
              meta: { label: i18n.t('profile') },
              props: true,
              beforeEnter(to, from, next) {
                window.location.href = v4VolunteerUrl(Number(to.params.volunteerId));
              },
            },
          ],
        },
        {
          path: 'volunteer',
          redirect: 'volunteer/list',
          name: 'app-Volunteers',
          meta: { label: 'Volunteers' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'app-volunteer-list',
              meta: { label: 'List' },
              beforeEnter(to, from, next) {
                window.location.href = v4Url('/volunteers/');
              },
            },
            {
              path: 'category',
              name: 'app-volunteer-category',
              meta: { label: 'Mes categories' },
              beforeEnter(to, from, next) {
                window.location.href = v4Url('/categories/');
              },
            },
            {
              path: ':volunteerId',
              name: 'app-volunteer-profile',
              meta: { label: i18n.t('profile') },
              props: true,
              beforeEnter(to, from, next) {
                window.location.href = v4VolunteerUrl(Number(to.params.volunteerId));
              },
            },
          ],
        },
        {
          path: 'manager',
          redirect: 'manager/list',
          name: 'app-Managers',
          meta: { label: 'Managers' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'list',
              name: 'app-Managers-list',
              meta: { label: 'Managers' },
              component: MANAGERLIST,
            },
          ],
        },
        {
          path: 'shop',
          redirect: 'Shop/Config',
          name: 'app-Shop',
          meta: { label: 'Shop' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'config',
              name: 'app-shop-config',
              meta: { label: 'Config' },
              component: SHOPCONFIG,
            },
          ],
        },
        {
          path: 'action',
          redirect: '/action/communication',
          name: 'app-Action',
          meta: { label: 'Action' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'print',
              name: 'app-Print',
              meta: { label: 'Impression / Plannings' },
              component: PRINT,
            },
            {
              path: 'print-ppb',
              name: 'app-Print-ppb',
              meta: { label: 'Impression / Plannings par bénévole' },
              component: PRINTPPB,
            },
            {
              path: 'print-tshirt',
              name: 'app-Print-tshirt',
              meta: { label: 'Impression / Listing des t-shirts' },
              component: PRINTTSHIRT,
            },
            {
              path: 'print-bon',
              name: 'app-Print-bon',
              meta: { label: 'Impression / Bons' },
              component: PRINTBON,
            },
          ],
        },
        {
          path: 'infos',
          redirect: '/infos/whatsnew',
          name: 'app-Infos',
          meta: { label: 'Infos' },
          component: {
            render(c: any) { return c('router-view'); },
          },
          children: [
            {
              path: 'whatsnew',
              name: 'app-whatsnew',
              meta: { label: 'Quoi de neuf?' },
              component: WHATSNEW,
            },
            {
              path: 'contact',
              name: 'Contact',
              meta: { label: 'Contact' },
              component: CONTACT,
            },
            {
              path: 'doc',
              name: 'app-doc',
              meta: { label: 'Documentation' },
              component: DOC,
            },
            {
              path: 'vlog',
              name: 'app-vlog',
              meta: { label: 'Vlog' },
              component: VLOG,
            },
            {
              path: 'soon',
              name: 'app-soon',
              meta: { label: 'À venir' },
              component: SOON,
            },
          ],
        },
      ],
    },



    // ACCOUNT PAGES
    {
      path: '/account',
      redirect: '/account/404',
      name: 'Account',
      meta: { requiresAuth: false },
      component: LOGINCONTAINER,
      children: [
        {
          path: 'login',
          name: 'public-login',
          meta: { requiresAuth: false },
          component: LOGIN,
        },
        {
          path: 'register',
          name: 'public-register',
          meta: { requiresAuth: false },
          component: REGISTER,
        },
        {
          path: 'verify-user',
          name: 'verify-user',
          meta: { requiresAuth: false },
          props: true,
          component: VERIFY,
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          meta: { requiresAuth: false },
          props: true,
          component: RESETPWD,
        },
        {
          path: 'reset-password-confirm',
          name: 'reset-password-confirm',
          meta: { requiresAuth: false },
          props: true,
          component: RESETPWDCONFIRM,
        },
        {
          path: 'logout',
          meta: { requiresAuth: false },
          name: 'public-logout',
          component: LOGOUT,
        },

      ],
    },
    {
      path: '/app/account',
      redirect: '/app/account/404',
      name: 'app-Account',
      meta: { requiresAuth: false },
      component: LOGINCONTAINER,
      children: [
        {
          path: 'login',
          name: 'app-public-login',
          meta: { requiresAuth: false },
          component: LOGIN,
        },
        {
          path: 'register',
          name: 'app-public-register',
          meta: { requiresAuth: false },
          component: REGISTER,
        },
        {
          path: 'verify-user',
          name: 'app-verify-user',
          meta: { requiresAuth: false },
          props: true,
          component: VERIFY,
        },
        {
          path: 'reset-password',
          name: 'app-reset-password',
          meta: { requiresAuth: false },
          props: true,
          component: RESETPWD,
        },
        {
          path: 'reset-password-confirm',
          name: 'app-reset-password-confirm',
          meta: { requiresAuth: false },
          props: true,
          component: RESETPWDCONFIRM,
        },
        {
          path: 'logout',
          meta: { requiresAuth: false },
          name: 'app-public-logout',
          component: LOGOUT,
        },

      ],
    },




    // PUBLIC IFRAME
    {
      path: '/iframe/:eventUUID',
      name: 'public_iframe_detail_uuid',
      meta: { requiresAuth: false, label: 'Inscriptions' },
      props: true,
      component: PUBLIC_EVENT_FRAME,
    },
    {
      path: '/app/iframe/:eventUUID',
      name: 'app_public_iframe_detail_uuid',
      meta: { requiresAuth: false, label: 'Inscriptions' },
      props: true,
      component: PUBLIC_EVENT_FRAME,
    },
    {
      path: '/manager/:eventUUID/:secret',
      name: 'manager_detail_uuid',
      meta: { requiresAuth: true, label: 'Manager' },
      props: true,
      component: MANAGER_EVENT,
    },
    {
      path: '/app/manager/:eventUUID/:secret',
      name: 'app_manager_detail_uuid',
      meta: { requiresAuth: true, label: 'Manager' },
      props: true,
      component: MANAGER_EVENT,
    },
    {
      path: '/o/:orgSlug/e/:eventUUID/embed',
      name: 'public_booking_embed',
      meta: { requiresAuth: false, label: 'Réservations' },
      props: true,
      component: PUBLIC_BOOKING_EMBED,
    },
    {
      path: '/app/o/:orgSlug/e/:eventUUID/embed',
      name: 'app_public_booking_embed',
      meta: { requiresAuth: false, label: 'Réservations' },
      props: true,
      component: PUBLIC_BOOKING_EMBED,
    },

    {
      path: '/iframe_login/:orgSlug/:logo',
      name: 'public_iframe_vol_login',
      meta: { requiresAuth: false, label: 'Espace bénévole' },
      component: VOLUNTEER_LOGIN,
      props: true,
    },
    {
      path: '/app/iframe_login/:orgSlug/:logo',
      name: 'app_public_iframe_vol_login',
      meta: { requiresAuth: false, label: 'Espace bénévole' },
      component: VOLUNTEER_LOGIN,
      props: true,
    },

    // PUBLIC PART
    {
      path: '/o/:orgSlug',
      props: true,
      meta: { requiresAuth: false, label: 'EHRO' },
      component: PUBLICCONTAINER,
      children: [
        {
          path: '/',
          name: 'org-home',
          meta: { requiresAuth: false, label: 'Organization' },
          props: true,
          component: PUBLIC_HOME,
        },
        {
          path: 'calendar',
          name: 'org-global-calendar',
          meta: { requiresAuth: false, label: 'Calendar' },
          props: true,
          component: PUBLIC_GLOBAL_CAL,
        },
        {
          path: 'volunteer-login',
          name: 'volunteer-events-list',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_LOGIN,
          props: true,
        },
        {
          path: 'volunteer-schedule/:token',
          name: 'volunteer-schedule',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_LOGIN_WITH_TOKEN,
          props: true,
        },
        {
          path: 'volunteer-schedule/:token/c/:cmpid/r/:regid',
          name: 'volunteer-schedule_cmp_detail',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_CMP_DETAIL,
          props: true,
        },
        {
          path: 'events',
          name: 'public_events_list',
          redirect: '/o/:orgSlug',
          meta: { requiresAuth: false, label: 'Events' },
          props: true,
          component: PUBLIC_HOME,
        },
        {
          path: 'event/',
          redirect: 'org-home',
          meta: { requiresAuth: false },
        },
        {
          path: 'event/:eventUUID',
          name: 'public_event_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },
        {
          path: 'e/:eventUUID',
          name: 'public_e_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },

        {
          path: 'e/:eventUUID/k/:tagkey',
          name: 'public_e_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },

        {
          path: 'manager/:eventUUID',
          name: 'manager_detail_uuid',
          meta: { requiresAuth: false, label: 'Manager' },
          props: true,
          component: FFG_MANAGER_EVENT,
        },

        {
          path: 'e-cal/:eventUUID',
          name: 'public_ecal_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_CAL_EVENT,
        },
        {
          path: 'c/:id',
          name: 'public_campaign_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_CAMPAIGN,
        },
        {
          path: 'contact',
          name: 'public_contact',
          meta: { requiresAuth: false, label: 'Contact' },
          props: true,
          component: PUBLIC_CONTACT,
        },
      ],
    },
    {
      path: '/app/o/:orgSlug',
      props: true,
      meta: { requiresAuth: false, label: 'EHRO' },
      component: PUBLICCONTAINER,
      children: [
        {
          path: '/',
          name: 'app-org-home',
          meta: { requiresAuth: false, label: 'Organization' },
          props: true,
          component: PUBLIC_HOME,
        },

        {
          path: 'event/:eventUUID',
          name: 'app_public_event_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },
        {
          path: 'e/:eventUUID',
          name: 'app_public_e_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },

        {
          path: 'e/:eventUUID/k/:tagkey',
          name: 'app_public_e_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: FFG_PUBLIC_EVENT,
        },

        {
          path: 'manager/:eventUUID',
          name: 'app_manager_detail_uuid',
          meta: { requiresAuth: false, label: 'Manager' },
          props: true,
          component: FFG_MANAGER_EVENT,
        },
        {
          path: 'calendar',
          name: 'app-org-global-calendar',
          meta: { requiresAuth: false, label: 'Calendar' },
          props: true,
          component: PUBLIC_GLOBAL_CAL,
        },
        {
          path: 'volunteer-login',
          name: 'app-volunteer-events-list',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_LOGIN,
          props: true,
        },
        {
          path: 'volunteer-schedule/:token',
          name: 'app-volunteer-schedule',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_LOGIN_WITH_TOKEN,
          props: true,
        },
        {
          path: 'volunteer-schedule/:token/c/:cmpid/r/:regid',
          name: 'app-volunteer-schedule_cmp_detail',
          meta: { requiresAuth: false, label: 'Espace bénévole' },
          component: VOLUNTEER_CMP_DETAIL,
          props: true,
        },
        {
          path: 'events',
          name: 'app-public_events_list',
          redirect: '/o/:orgSlug',
          meta: { requiresAuth: false, label: 'Events' },
          props: true,
          component: PUBLIC_HOME,
        },
        {
          path: 'event/',
          redirect: 'app-org-home',
          meta: { requiresAuth: false },
        },
        {
          path: 'event/:eventUUID',
          name: 'app-public_event_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_EVENT,
        },
        {
          path: 'e/:eventUUID',
          name: 'app-public_e_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_EVENT,
        },
        {
          path: 'e-cal/:eventUUID',
          name: 'app-public_ecal_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_CAL_EVENT,
        },
        {
          path: 'c/:id',
          name: 'app-public_campaign_detail_uuid',
          meta: { requiresAuth: false, label: 'Inscriptions' },
          props: true,
          component: PUBLIC_CAMPAIGN,
        },
        {
          path: 'contact',
          name: 'app-public_contact',
          meta: { requiresAuth: false, label: 'Contact' },
          props: true,
          component: PUBLIC_CONTACT,
        },
      ],
    },

    { path: '*', redirect: '/404' },
  ],
});

router.beforeEach((
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void,
) => {
  if (process.env.VUE_APP_BASE_API_URL === 'http://localhost:8000') {
    document.title = 'LOCAL SUPER EHRO';
  } else if (process.env.VUE_APP_BASE_API_URL === 'https://api.staging.ehro.app') {
    document.title = 'STAGING SUPER EHRO';
  } else {
    document.title = 'SUPER EHRO';
  }


  let isSuperUser = false;
  if (AuthenticationService.instance.isSuperAdmin) {
    isSuperUser = true;
  }

  const lang = AuthenticationService.instance.language;


  // HACK CHAMPAGNE !!!!
  if (AuthenticationService.instance.permissionLib.isHackCHAMPAGNESALLES || AuthenticationService.instance.permissionLib.isPublicHackCHAPAGNESALLES) {
    import(`@/lang/fr_champagne`).then((msgs) => {
      i18n.setLocaleMessage('fr', msgs.default);
      i18n.locale = 'fr';
    });
  } else {
    import(`@/lang/${lang}`).then((msgs) => {
      i18n.setLocaleMessage(lang, msgs.default);
      i18n.locale = lang;
    });
  }

  if (to.hash.startsWith('#/')) {
    let url = process.env.VUE_APP_ADMIN_SITE + 'app/' + to.hash.substring(2);
    window.location.href = url;
    return;
  }

  // Si la destination ne nécessite pas de login, on l'affiche
  if ((to.meta !== undefined && to.meta.requiresAuth !== undefined && !to.meta.requiresAuth)) {
    next();
  } else if (to.name === 'public-login') {
    next();
  } else if (!AuthenticationService.instance.loggedIn) {
    next({
      name: 'public-login',
      query: { redirect: to.fullPath },
    });
  } else if (to.name !== 'public-login') {
    if (!AuthenticationService.instance.loggedIn) {
      next({
        name: 'public-login',
        query: { redirect: to.fullPath },
      });
    } else if (AuthenticationService.instance.authenticatedUser!.currentOrganization == null) {
      AuthenticationService.instance.getOrganizations(AuthenticationService.instance.authenticatedUser!).then((organization: OrganizationModel | undefined) => {
        next();
      }).catch((error: any) => {
        next({
          name: 'public-login',
          query: { redirect: to.fullPath },
        });
      });
    } else {
      next();
    }
  } else {
    // Cas normal, on avance
    next();
  }
});

export default router;
