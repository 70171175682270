import { DateTime, Interval } from 'luxon';

export class ShopProductModel {

  public id: number | null;
  public name: string;
  public description: string;
  public price: string;
  public image: string;
  public status: string;
  public onSale: boolean;

  constructor({
    id = null,
    name = '',
    description = '',
    price = '',
    image = '',
    status = '',
    onSale = false,
  }: {
    id?: number | null,
    name?: string,
    description?: string,
    price?: string,
    image?: string,
    status?: string,
    onSale?: boolean,

  } = {}) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.price = price;
    this.image = image;
    this.status = status;
    this.onSale = onSale;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      price: this.price,
      image: this.image,
      status: this.status,
      onSale: this.onSale,
    };
  }

}