import i18n from '@/i18n';
import { CustomFieldModel } from '@/models/CustomFieldModel';
import { Field } from 'vee-validate';

// export enum DisplayNameType {
//   Nothing = 'nothing',
//   FullName = 'full_name',
//   Abbreviation = 'last_name_abbreviated',
// }



export class ConfigurationModel {

  public static empty() {
    return new ConfigurationModel();
  }

  public displayNameOpts = [
    { text: i18n.t('vol_display_name_nothing'), value: 'nothing' },
    { text: i18n.t('vol_display_name_full'), value: 'full_name' },
    { text: i18n.t('vol_display_name_abbreviated'), value: 'last_name_abbreviated' },
  ];

  public get orderedFields(): CustomFieldModel[] {
    return this.customFields.slice().sort((a, b) => {
      if (a.order < b.order) { return -1; }
      if (a.order > b.order) { return 1; }
      return 0;
    });
  }

  public updateFieldsOrder() {
    this.customFields.forEach((field, i) => {
      field.order = i;
    });
  }


  public volunteerNameDisplay: string;
  public emailMandatory: boolean;
  public askForPhone: boolean;
  public phoneMandatory: boolean;
  public askForTshirt: boolean;
  public askForPatisserie: boolean;
  public askForMember: boolean;
  public sendMeCopyOfRegistration: boolean;
  public registrationEmailText: string;
  public askForBornDate: boolean;
  public customFields: CustomFieldModel[];
  public enableGroupRegistrations: boolean;
  public hideFullSlots: boolean;
  public forceConfirmationEmail: boolean;
  public allowPublicTimeSlotCreation: boolean;
  public enableRegistrations: boolean;
  public showPastSlots: boolean;
  public askForAddress: boolean;
  public addressMandatory: boolean;
  public colorFree: string;
  public colorFull: string;
  public enableBasket: boolean;
  public closePositionByDefault: boolean;

  constructor({
    askForAddress = false,
    addressMandatory = false,
    volunteerNameDisplay = 'nothing',
    allowPublicTimeSlotCreation = false,
    enableRegistrations = true,
    showPastSlots = false,
    emailMandatory = false,
    askForPhone = false,
    phoneMandatory = false,
    askForTshirt = false,
    askForPatisserie = false,
    askForMember = false,
    askForBornDate = false,
    enableGroupRegistrations = false,
    sendMeCopyOfRegistration = false,
    enableBasket = false,
    hideFullSlots = false,
    closePositionByDefault = false,
    forceConfirmationEmail = false,
    registrationEmailText = 'Hello [[volunteer_name]],\n\n\
Merci pour votre inscription à [[event_name]] !\n\n\
[[registration_resume]]\n\n\
En cas de questions, vous pouvez vous adresser à [[responsable_name]] par email : [[responsable_email]].\n\n\
Merci, sans vous, nous ne pourrions mettre sur pied notre manifestation. :-)\n\n',
    customFields = [],
    colorFree = '#4fd68e',
    colorFull = '#d65f4f',

  }: {
    askForAddress?: boolean,
    addressMandatory?: boolean,
    volunteerNameDisplay?: string,
    allowPublicTimeSlotCreation?: boolean,
    enableRegistrations?: boolean,
    showPastSlots?: boolean,
    emailMandatory?: boolean,
    askForPhone?: boolean,
    phoneMandatory?: boolean,
    askForTshirt?: boolean,
    askForPatisserie?: boolean,
    askForMember?: boolean,
    askForBornDate?: boolean,
    enableGroupRegistrations?: boolean,
    sendMeCopyOfRegistration?: boolean,
    hideFullSlots?: boolean,
    forceConfirmationEmail?: boolean,
    closePositionByDefault?: boolean,
    registrationEmailText?: string;
    customFields?: CustomFieldModel[],
    colorFree?: string,
    colorFull?: string,
    enableBasket?: boolean,
  } = {}) {
    this.addressMandatory = addressMandatory;
    this.askForAddress = askForAddress;
    this.volunteerNameDisplay = volunteerNameDisplay;
    this.enableRegistrations = enableRegistrations;
    this.showPastSlots = showPastSlots;
    this.emailMandatory = emailMandatory;
    this.askForPhone = askForPhone;
    this.phoneMandatory = phoneMandatory;
    this.askForTshirt = askForTshirt;
    this.askForPatisserie = askForPatisserie;
    this.closePositionByDefault = closePositionByDefault;
    this.askForMember = askForMember;
    this.askForBornDate = askForBornDate;
    this.enableGroupRegistrations = enableGroupRegistrations;
    this.sendMeCopyOfRegistration = sendMeCopyOfRegistration;
    this.registrationEmailText = registrationEmailText;
    this.hideFullSlots = hideFullSlots;
    this.forceConfirmationEmail = forceConfirmationEmail;
    this.allowPublicTimeSlotCreation = allowPublicTimeSlotCreation;
    this.colorFree = colorFree;
    this.colorFull = colorFull;
    this.enableBasket = enableBasket;
    this.customFields = customFields.map((d: any): CustomFieldModel => {
      if (d instanceof CustomFieldModel) {
        return d;
      } else {
        return new CustomFieldModel(d);
      }
    });
    this.updateFieldsOrder();
  }

  public toJSON(): any {
    return {
      askForAddress: this.askForAddress,
      addressMandatory: this.addressMandatory,
      allowPublicTimeSlotCreation: this.allowPublicTimeSlotCreation,
      enableRegistrations: this.enableRegistrations,
      showPastSlots: this.showPastSlots,
      volunteerNameDisplay: this.volunteerNameDisplay,
      emailMandatory: this.emailMandatory,
      askForPhone: this.askForPhone,
      phoneMandatory: this.phoneMandatory,
      askForTshirt: this.askForTshirt,
      askForPatisserie: this.askForPatisserie,
      askForMember: this.askForMember,
      askForBornDate: this.askForBornDate,
      enableGroupRegistrations: this.enableGroupRegistrations,
      sendMeCopyOfRegistration: this.sendMeCopyOfRegistration,
      hideFullSlots: this.hideFullSlots,
      forceConfirmationEmail: this.forceConfirmationEmail,
      registrationEmailText: this.registrationEmailText,
      selectedCustomFields: this.customFields.map((customField) => customField.id),
      colorFree: this.colorFree,
      colorFull: this.colorFull,
      enableBasket: this.enableBasket,
      closePositionByDefault: this.closePositionByDefault,
    };
  }

  get displayVolunteer() {
    return this.volunteerNameDisplay !== 'nothing';
  }

}
