import { CampaignModel } from './CampaignModel';

export class CampaignRegistrationResumeModel {
  public id: number | null = null;
  public campaign: CampaignModel;
  public createdAt: Date | null = null;
  public modifiedAt: Date | null = null;

  constructor({
    id = null,
    campaign = new CampaignModel(),
    createdAt = null,
    modifiedAt = null,
  }: {
    id?: number | null,
    campaign?: CampaignModel,
    createdAt?: Date | null,
    modifiedAt?: Date | null,
  } = {}) {
    this.id = id;
    this.campaign = campaign;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    if (campaign instanceof CampaignModel) {
      this.campaign = campaign;
    } else {
      this.campaign = new CampaignModel(campaign);
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      campaign: this.campaign,
      createdAt: this.createdAt,
      modifiedAt: this.modifiedAt,
    };
  }
}
