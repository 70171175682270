import { CustomFieldModel } from './CustomFieldModel';
import { ParticipationModel } from './ParticipationModel';

export class CustomAnswerModel {

  public static copy(ca: CustomAnswerModel): CustomAnswerModel {
    const cp = new CustomAnswerModel();
    cp.id = ca.id;
    cp.answers = ca.answers.slice();
    return cp;
  }

  public id: number | null = null;
  public field: number | null = null;
  public participation: number | null = null;
  public answers: Array<string | boolean>;

  // Réf
  public fieldModel: CustomFieldModel | null = null;
  // public participationModel: ParticipationModel | null = null;

  // Resume ...
  public fieldTitle: string;
  public fieldType: number;
  public selectValues: string;



  constructor({
    id = null,
    field = null,
    participation = null,
    answers = [],
    fieldTitle = '',
    fieldType = 666,
    selectValues = '',
    fieldId = null,
  }: {
    id?: number | null,
    field?: number | null,
    participation?: number | null,
    answers?: Array<string | boolean>,
    fieldTitle?: string,
    fieldType?: number,
    selectValues?: string,
    fieldId?: number | null,
  } = {}) {
    this.id = id;
    this.field = field;
    this.participation = participation;
    this.answers = answers;
    this.fieldTitle = fieldTitle;
    this.fieldType = fieldType;
    this.selectValues = selectValues;
    if (this.field === null && fieldId !== null) {
      this.field = fieldId;
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      field: this.field,
      participation: this.participation,
      answers: this.answers,
      fieldTitle: this.fieldTitle,
      fieldType: this.fieldType,
      selectValues: this.selectValues,
    };
  }
}

