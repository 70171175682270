import { EventModel } from './EventModel';

export class CommunicationHistory {
  public id: number | null = null;
  public eventId: number | null = null;
  public eventName: string | null = null;
  public sender: string = '';
  public subject: string = '';
  public text: string = '';
  public filters: string | null = null;
  public recipientsList: string[] = [];
  public sentAt: Date | null = null;

  constructor({
    id = null,
    eventId = null,
    eventName = null,
    sender = '',
    subject = '',
    text = '',
    filters = null,
    recipientsList = [],
    sentAt = null,
  }: {
    id?: number | null,
    eventId?: number | null,
    eventName?: string | null,
    sender?: string,
    subject?: string,
    text?: string,
    filters?: string | null,
    recipientsList?: string[],
    sentAt?: Date | null,
  } = {}) {
    this.id = id;
    this.eventId = eventId;
    this.eventName = eventName;
    this.text = text;
    this.subject = subject;
    this.sender = sender;
    this.filters = filters;
    this.recipientsList = recipientsList;
    this.sentAt = sentAt;
  }

  get nbrRecipients() {
    return this.recipientsList.length;
  }
}
