import { TimeSlotModel } from './TimeSlotModel';
import { CustomAnswerResumeModel } from './CustomAnswerResumeModel';
import { EventModel } from './EventModel';
import { DateUtils } from '@/utils/date';
import { DateTime } from 'luxon';
import i18n from '@/i18n';
import { PositionModel } from './PositionModel';
import { RewardCategoryModel } from './RewardCategoryModel';

export class TimeSlotResumeModel {

  public static copyFromTimeSlotModel(ts: TimeSlotModel): TimeSlotResumeModel {
    const tsr = new TimeSlotResumeModel();
    tsr.id = ts.id;
    tsr.startTime = ts.startTime;
    tsr.rewardCategory = ts.rewardCategory;
    tsr.endTime = ts.endTime;
    return tsr;
  }

  public id: number | null = null;
  public startTime: DateTime;
  public endTime: DateTime;
  public positionName: string = '';
  public sectorName: string = '';
  public description: string;
  public positionDescription: string = '';
  public sectorDescription: string = '';
  public participationId: number | null = null;
  public eventId: number | null = null;
  public eventName: string = '';
  public event: EventModel | null = null;
  public duration: number = 0;

  public referenceMemberFirstName: string = '';
  public referenceMemberLastName: string = '';
  public patisserie: string = '';
  public customAnswer: CustomAnswerResumeModel[] = [];
  public createdAt: Date | null = null;

  public position: PositionModel | null = null;

  // Reward
  public rewardCategory: number | null = null;

  constructor({
    id = null,
    startTime = DateTime.fromMillis(0),
    endTime = DateTime.fromMillis(0),
    description = '',
    positionName = '',
    sectorName = '',
    positionDescription = '',
    sectorDescription = '',
    duration = 0,
    rewardCategory = null,
  }: {
    id?: number | null,
    description?: string,
    startTime?: DateTime,
    endTime?: DateTime,
    positionName?: string,
    sectorName?: string,
    positionDescription?: string,
    sectorDescription?: string,
    duration?: number,
    rewardCategory?: number | null,
  } = {}) {
    this.id = id;
    this.description = description;
    this.startTime = startTime;
    this.endTime = endTime;
    this.positionName = positionName;
    this.sectorName = sectorName;
    this.positionDescription = positionDescription;
    this.sectorDescription = sectorDescription;
    this.duration = duration;
    this.rewardCategory = rewardCategory;
  }

  public get printName() {
    if (this.position) {
      return this.position.name;
    } else {
      return this.positionName;
    }
  }

  get startDateFormatted() {
    return this.startTime.startOf('day').toFormat('dd.LL.yyyy');
  }
  get startPrintDateFormatted() {
    return this.startTime.startOf('day').toFormat('ccc dd.MM.yyyy', { locale: i18n.locale });
  }
  get startTimeFormatted() {
    return this.startTime.toFormat('HH:mm');
  }
  get endTimeFormatted() {
    return this.endTime.toFormat('HH:mm');
  }

  public get timeInfo() {
    var diffInDay = this.endTime.startOf('day').diff(this.startTime.startOf('day'), 'day');
    diffInDay.toObject();
    const daysOffset = this.endTime.day - this.startTime.day;
    return `${this.startTime.toFormat('HH:mm')} - ${this.endTime.toFormat('HH:mm')}` +
      (daysOffset > 1 ? ` (+${daysOffset}${i18n.t('jour_small')})` : '');
  }
}
