export class CampaignAnswersResumeModel {
  public campaignId: string;
  public answers: any[] = [];

  constructor({
    campaignId = '',
    answers = [],
  }: {
    campaignId?: string,
    answers?: any[],
  } = {}) {
    this.campaignId = campaignId;
    this.answers = answers;
  }
}
