import axios, { AxiosInstance } from 'axios';
import { AUTHENTICATION_USE_INTERCEPTOR, AUTHENTICATION_REJECT_INTERCEPTOR} from '@/interceptors';

export class AuthenticationAPI {

  private static internal: AxiosInstance;

  public static get instance(): AxiosInstance {
    if (!AuthenticationAPI.internal) {
      AuthenticationAPI.internal = axios.create({
        baseURL: process.env.VUE_APP_AUTHENTICATION_API_URL,
      });
    }
    AuthenticationAPI.internal.interceptors.request.use(
      AUTHENTICATION_USE_INTERCEPTOR,
      AUTHENTICATION_REJECT_INTERCEPTOR,
    );
    return AuthenticationAPI.internal;
  }

}

