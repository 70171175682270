export class CustomFieldModel {

  public id: number | null = null;
  public organizationId: number | null = null;
  public title: string;
  public isMandatory: boolean;
  public fieldType: number;
  public selectValues: string[] | null = null;

  public userAnswer: string = '';
  public groupUserAnswer: number[] = [];
  public boolAnswer: boolean = false;
  public order: number;

  public get isGrouped() {
    return this.selectValues && this.selectValues.length >= 6;
  }

  constructor({
    id = null,
    organizationId = null,
    title = '',
    isMandatory = false,
    fieldType = 1,
    order = 0,
    selectValues = [],
  }: {
    id?: number | null,
    organizationId?: number | null,
    title?: string,
    order?: number,
    isMandatory?: boolean,
    fieldType?: number,
    selectValues?: string[] | null;
  } = {}) {
    this.id = id;
    this.organizationId = organizationId;
    this.title = title;
    this.order = order;
    this.isMandatory = isMandatory;
    this.fieldType = fieldType;
    this.selectValues = selectValues;
    if (this.isChoiceField()) {
      if (this.selectValues === null) {
        this.selectValues = [];
      }

      if (this.groupUserAnswer.length === 0) {
        this.selectValues.forEach((s) => {
          this.groupUserAnswer.push(0);
        });
      }
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      organizationId: this.organizationId,
      title: this.title,
      order: this.order,
      isMandatory: this.isMandatory,
      isGrouped: this.isGrouped,
      fieldType: this.fieldType,
      selectValues: this.selectValues,
    };
  }

  public isChoiceField(): boolean {
    return this.fieldType === 2;
  }

  public isBooleanField(): boolean {
    return this.fieldType === 1;
  }
}
