
export class VolunteerCategoryModel {

  public id: number | null = null;
  public organizationId: number | null = null;
  public name: string;
  public isByDefault: boolean = false;

  constructor({
    id = null,
    organizationId = null,
    name = '',
    isByDefault = false,
  }: {
    id?: number | null,
    organizationId?: number | null,
    name?: string,
    isByDefault?: boolean,
  } = {}) {
    this.id = id;
    this.organizationId = organizationId;
    this.name = name;
    this.isByDefault = isByDefault;
  }

  public toJSON(): any {
    return {
      id: this.id,
      organizationId: this.organizationId,
      name: this.name,
      isByDefault: this.isByDefault,
    };
  }
}
