import { DateTime } from 'luxon';
import i18n from '@/i18n';

export class EventDayModel {
  public startsAt: DateTime;
  public endsAt: DateTime;

  constructor({
    startsAt = DateTime.fromMillis(0),
    endsAt = DateTime.fromMillis(0),
  }: {
    startsAt?: DateTime,
    endsAt?: DateTime,
  } = {}) {
    this.startsAt = startsAt;
    this.endsAt = endsAt;
  }

  public toJSON(): any {
    return {
      startsAt: this.startsAt.toISO(),
      endsAt: this.endsAt.toISO(),
    };
  }

  public get date() {
    return this.startsAt.startOf('day').toJSDate();
  }

  public dayFormatted() {
    return this.startsAt.toFormat('ccc dd.MM.yyyy', { locale: i18n.locale });
  }

  public isoFormatted() {
    return this.startsAt.toISO() ? String(this.startsAt.toISO()) : this.dayFormatted();
  }

  public get startOfDayDateOfStarts(): DateTime {
    return this.startsAt.startOf('day');
  }
  public get startOfDayDateOfEnds(): DateTime {
    // cas ou sa se termine à minuit
    if (this.endsAt.hour === 0 && this.endsAt.minute === 0) {
      return this.endsAt.minus({ days: 1 }).startOf('day');
    }
    return this.endsAt.startOf('day');
  }

  public startsAtYYYYMMDD() {
    return this.startOfDayDateOfStarts.toFormat('yyyy-LL-dd');
  }
  public endsAtYYYYMMDD() {
    return this.startOfDayDateOfEnds.toFormat('yyyy-LL-dd');
  }

  public hasInRange(start: DateTime, end: DateTime) {
    return this.startsAt.toMillis() <= start.toMillis() && this.endsAt.toMillis() >= end.toMillis();
  }

  public isInPast(): boolean {
    return this.endsAt.toMillis() < DateTime.now().toMillis();
  }
}
