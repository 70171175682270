import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthenticationAPI, AccountAPI } from '@/apis';
import { UserModel } from '@/models/UserModel';

import Vue from 'vue';
import i18n from '@/i18n';
import { LicenseType, OrganizationModel } from '@/models/OrganizationModel';
import { or } from 'vuelidate/lib/validators';
import { PermissionLib } from './PermissionLib';
import { toLocalLocale } from '../utils/i18n';

export class AuthenticationService {
  private static internal: AuthenticationService = new AuthenticationService(AuthenticationAPI.instance);

  public static get instance(): AuthenticationService {
    return AuthenticationService.internal;
  }

  public publicOrganization: OrganizationModel | null = null;

  private _authenticatedUser: UserModel | null = null;
  public permissionLib = new PermissionLib();


  constructor(private api: AxiosInstance) {
  }

  public setOrganization(id: number) {
    return this.api.post(`/organizations/${id}/switch/`).then((response: AxiosResponse) => {
      if (this._authenticatedUser) {
        this._authenticatedUser.activeOrganization = response.data.id;
        if (this._authenticatedUser.currentOrganization) {
          this._authenticatedUser.currentOrganization.nbPendingValidations = response.data.nbPendingValidations;
        }
        this.updateUser(this._authenticatedUser);
      }
      return response.data;
    }).catch((error: any) => {
      throw error;
    });
  }
  

  public getOrganizations(user: UserModel): Promise<OrganizationModel | undefined> {
    return this.api.get(`/organizations/`).then((response: AxiosResponse) => {

      user.activeOrganization = response.data.activeOrganization;

      // HACK CHAMPAGNE !!!!
      if (this.permissionLib.isHackCHAMPAGNESALLES || this.permissionLib.isPublicHackCHAPAGNESALLES) {
        import(`@/lang/fr_champagne`).then((msgs) => {
          i18n.setLocaleMessage('fr', msgs.default);
          i18n.locale = 'fr';
          AuthenticationService.instance.updateLanguage('fr');
        });
      }

      user.availableOrganizations = response.data.availableOrganizations.map((d: any): OrganizationModel => {
        let org;
        if (d instanceof OrganizationModel) {
          org = d;
        } else {
          org = new OrganizationModel(d);
        }
        return org;
      });
      return user.availableOrganizations.find((org) => {
        return org.id === user.activeOrganization;
      });
    }).catch((error: any) => {
      throw error;
    });
  }


  public isOrgId(id: number): boolean {
    if (this._authenticatedUser && this._authenticatedUser.currentOrganization) {
      if (this._authenticatedUser.currentOrganization.id) {
        return this._authenticatedUser.currentOrganization.id === id;
      }
    }
    return false;
  }

  public updateUser(user: UserModel) {
    localStorage.setItem('newAuthUser', JSON.stringify(user));
  }

  // 2fa
  public getDevicesList() {
    return this.api.get('/otp-devices/').then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public getMethodList() {
    return this.api.get('/otp-methods/').then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public setupMethod(methodCode: string) {
    return this.api.post(`/otp-methods/${methodCode}/setup/`).then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public deleteDevice(deviceId: string) {
    return this.api.delete(`/otp-devices/${deviceId}/`).then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public validDevice(device: string, token: string, secretKey: string) {
    return this.api.post(`/otp-validate/`, {
      token,
      device,
      secret: secretKey,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public challenge(device: string) {
    return this.api.post(`/otp-challenge/`, {
      device,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public verifyChallenge(device: string, token: string) {
    return this.api.post('/otp-verify/', {
      token,
      device,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }

  public getBackupCodes() {
    return this.api.get('/otp-backup/').then((response: AxiosResponse) => {
      return response.data;
    });
  }
  public generateNewBackupCodes() {
    return this.api.post('/otp-backup/').then((response: AxiosResponse) => {
      return response.data;
    });
  }


  public updateLanguage(language: string): Promise<UserModel> {
    const user = this._authenticatedUser!;
    Vue.$cookies.set('django_language', language, '730d', '/', process.env.VUE_APP_SITE_DOMAIN);
    if (user !== null) {
      return this.updateUserProfile(user.email, user.firstName, user.lastName, language);
    }

    return Promise.resolve(user);
  }

  public updateUserProfile(
    email: string,
    firstName: string,
    lastName: string,
    language: string,
  ): Promise<UserModel> {
    return this.updateProfile(email, firstName, lastName, language,
      null, null, null, null, null, null, null, null, null);
  }


  public updateProfile(
    email: string,
    firstName: string,
    lastName: string,
    language: string,
    imageLogo: string | null,
    description: string | null,
    publicName: string | null,
    contactName: string | null,
    contactEmail: string | null,
    contactPhone: string | null,
    contactInfo: string | null,
    displayPublicList: boolean | null,
    displayPublicContact: boolean | null,
  ): Promise<UserModel> {
    const data = {} as any;
    data.username = email;
    data.email = email;
    data.firstName = firstName;
    data.lastName = lastName;
    data.language = language === 'fr' ? 'fr-ch' : language;

    if (imageLogo !== null) { data.imageLogo = imageLogo; }
    if (description !== null) { data.description = description; }
    if (publicName !== null) { data.publicName = publicName; }
    if (contactName !== null) { data.contactName = contactName; }
    if (contactEmail !== null) { data.contactEmail = contactEmail; }
    if (contactPhone !== null) { data.contactPhone = contactPhone; }
    if (contactInfo !== null) { data.contactInfo = contactInfo; }
    if (displayPublicList !== null) { data.displayPublicList = displayPublicList; }
    if (displayPublicContact !== null) { data.displayPublicContact = displayPublicContact; }

    return this.api.post('/profile/', data).then((response: AxiosResponse) => {
      const user = this._authenticatedUser!;

      if (response.data.organization!.logo !== null) {
        user.currentOrganization!.logo = response.data.organization.logo;
      }
      if (description !== null) { user.currentOrganization!.description = description; }
      if (publicName !== null) { user.currentOrganization!.publicName = publicName; }
      if (contactName !== null) { user.currentOrganization!.contactName = contactName; }
      if (contactEmail !== null) { user.currentOrganization!.contactEmail = contactEmail; }
      if (contactPhone !== null) { user.currentOrganization!.contactPhone = contactPhone; }
      if (contactInfo !== null) { user.currentOrganization!.contactInfo = contactInfo; }
      if (displayPublicList !== null) { user.currentOrganization!.displayPublicList = displayPublicList; }
      if (displayPublicContact !== null) { user.currentOrganization!.displayPublicContact = displayPublicContact; }

      user.email = email;
      user.firstName = firstName;
      user.lastName = lastName;
      user.language = language;
      this.updateUser(user);
      return this._authenticatedUser!;
    }).catch((error: any) => {
      throw error;
    });
  }

  public cleanCookies() {
    localStorage.removeItem('newAuthUser');
  }

  public djangoLogin(): Promise<any> {
    return this.api.post('/login-token/');
  }

  public login(email: string, password: string): Promise<UserModel> {
    return this.performLogin({
      email,
      password,
    });
  }

  public register(email: string, password: string, passwordConfirm: string): Promise<void> {
    const data = {
      username: email,
      email,
      password,
      password_confirm: passwordConfirm,
    };
    return this.api.post('/accounts/register/', data).then((response: AxiosResponse) => {
      console.log('[AuthenticationService]-[createAccount] : SUCCESS');
    }).catch((error: any) => {
      throw error;
    });
  }

  public verifyRegistration(userId: string, timestamp: string, signature: string): Promise<void> {
    const data = {
      user_id: userId,
      timestamp,
      signature,
    };
    return this.api.post('/accounts/verify-registration/', data).then((response: AxiosResponse) => {
      console.log('[AuthenticationService]-[verifyRegistration] : SUCCESS');
    }).catch((error: any) => {
      throw error;
    });
  }

  public askForResetPasswordLink(email: string): Promise<void> {
    const data = {
      login: email,
    };
    return this.api.post('/accounts/send-reset-password-link/', data).then((response: AxiosResponse) => {
      console.log('[AuthenticationService]-[askForResetPasswordLink] : SUCCESS');
    }).catch((error: any) => {
      throw error;
    });
  }

  public resetPassword(password: string, userId: string, timestamp: string, signature: string): Promise<void> {
    const data = {
      password,
      user_id: userId,
      timestamp,
      signature,
    };
    return this.api.post('/accounts/reset-password/', data).then((response: AxiosResponse) => {
      console.log('[AuthenticationService]-[resetPassword] : SUCCESS');
    }).catch((error: any) => {
      throw error;
    });
  }


  public logout(): Promise<void> {
    return this.api.post('/logout/').then((response: AxiosResponse) => {
      this.cleanCookies();
      this._authenticatedUser = null;
    }).catch((error: any) => {
      this.cleanCookies();
      this._authenticatedUser = null;
    });
  }

  public refresh(): Promise<UserModel> {
    console.log('refresh');

    return this.performLogin({});
  }

  public get authenticatedUser(): UserModel | null {
    if (this._authenticatedUser === null) {
      const newAuthUserJSON = localStorage.getItem('newAuthUser');

      if (newAuthUserJSON != null) {
        const djangoLanguage = Vue.$cookies.get('django_language');

        this._authenticatedUser = new UserModel(JSON.parse(newAuthUserJSON));

        if (djangoLanguage !== null) {
          this._authenticatedUser.language = djangoLanguage;
        }
      }
    }
    return this._authenticatedUser;
  }

  public get loggedIn(): boolean {
    return this.authenticatedUser !== null;
  }

  public get isSuperAdmin(): boolean {
    if (this.authenticatedUser) {
      return this.authenticatedUser.isStaff
        || this.authenticatedUser.isSuperUser;
    }
    return false;
  }


  public get langs() {
    if (this.permissionLib.isHackCHAMPAGNESALLES || this.permissionLib.isPublicHackCHAPAGNESALLES) {
      return [{ file: 'fr_champagne', lang: 'fr', code: 'fr' }];
    }
    return [{ file: 'fr', lang: 'fr', code: 'fr' }, { file: 'en', lang: 'en', code: 'en' }, { file: 'de', lang: 'de', code: 'de' }];
  }

  public changeLocal(newLang: string) {    
    let changeTo = newLang;
    if (newLang === '' || newLang === 'fr-ch') {
      changeTo = 'fr';
    }

    const newLocale = this.langs.find((locale) => locale.lang === changeTo);
    if (newLocale) {
      import(`@/lang/${newLocale.file}`).then((msgs) => {
        i18n.setLocaleMessage(newLocale.lang, msgs.default);
        i18n.locale = newLocale.lang;
        AuthenticationService.instance.updateLanguage(newLocale.code);
      });
    }
  }


  public get language(): string {
    if (this.authenticatedUser) {
      return toLocalLocale(this.authenticatedUser.language);
    }

    return toLocalLocale(Vue.$cookies.get('django_language')) || 'fr';
  }


  private performLogin(data: any): Promise<UserModel> {
    // withCredentials: true is mandatory here to save cookies returned by the
    // login endpoint. withCredentials: true is also set in the interceptor but
    // only if the user is logged in, which is not the case here
    return this.api.post('/login/', data, { withCredentials: true }).then((response: AxiosResponse) => {
      // if (response.data.organizations === undefined || !(response.data.organizations.length > 0)) {
      //   throw new Error('The user has no organization set, please set one in the admin');
      // }
      this._authenticatedUser = new UserModel(response.data);
      // this._authenticatedUser.currentOrganization = this._authenticatedUser.organizations[0];
      this.updateUser(this._authenticatedUser);

      return this._authenticatedUser;
    }).catch((error: any) => {
      if (error.response) {
        // Not a network error, user must be cleared
        this._authenticatedUser = null;
      }
      this.cleanCookies();
      this._authenticatedUser = null;
      throw error;
    });
  }
}
