import * as Utils from '@/assets/js/Utils.js';

export class TagModel {

  public id: number | null = null;
  public name: string;
  public category: TagModel | null = null;
  public createdAt: Date | null;
  public visibility: number = 1;
  public color: string = '';
  public order: number = 0;

  constructor({
    id = null,
    name = '',
    category = null,
    createdAt = null,
    visibility = 1, // 0 = interne 1 = public
    color ='',
    order = 0,
  }: {
    id?: number | null,
    name?: string,
    category?: TagModel | null,
    createdAt?: Date | null,
    visibility?: number,
    color?: string,
    order?: number,
  } = {}) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.visibility = visibility;
    this.createdAt = createdAt;
    this.color = color;
    this.order = order;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
    };
  }

  public get fullName(): string {
    if (this.category) {
      return this.category.name + ' - ' + this.name;
    }
    return this.name;
  }

  public get style(): string {
    return `background-color:${this.backgroundColor}; color:${this.textColor}`;
  }

  get backgroundColor() {
    let color = '#ffc107';
    if (this.category && this.category.color !== '') {
      color = this.category.color;
    } else if (this.color !== '') {
      color = this.color;
    }
    return color;
  }
  get textColor() {
    return Utils.getTextColor(this.backgroundColor);
  }
}

