export type OccupationType = 'MorningOnly' | 'AfternoonOnly' | 'FullDay';

export class DateUtils {

  public static dateToISODate(d: Date | null): any {
    if (d) {
      if (Object.prototype.toString.call(d) === '[object Date]') {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return [year, month, day].join('-');
      } else {

        if (this.euStringDateToDate(String(d))) {
          return this.dateToISODate(this.euStringDateToDate(String(d)));
        }

        return d;
      }
    } else {
      return null;
    }
  }

  public static euStringDateToDate(s: string): Date | null {
    const regexEU = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{4}$/;
    if (regexEU.test(s)) {
      const vals = s.split('.');
      if (vals.length === 3) {
        return new Date(vals[2] + '-' + vals[1] + '-' + vals[0]);
      }
    }
    return null;
  }

  public static dateToEuString(d: Date): string {
    if (d) {
      if (Object.prototype.toString.call(d) === '[object Date]') {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return [day, month, year].join('.');
      }
      console.log('NOT A DATE');
      const newdate = new Date(d);
      if (Object.prototype.toString.call(newdate) === '[object Date]') {
        console.log('ITS A DATE');
        return this.dateToEuString(newdate);

      }

    }
    return '';
  }

  public static dateToISODateTime(d: Date): any {
    return d.toISOString();
  }

  public static YYYYMMDD(d: Date): any {
    const offset = d.getTimezoneOffset();
    d = new Date(d.getTime() - (offset * 60 * 1000));
    return d.toISOString().split('T')[0];
  }

  public static durationToISODuration(d: number) {
    const h = Math.floor(d / (60 * 60));
    const m = Math.floor((d % (60 * 60)) / 60);
    const s = d % 60;
    return `P0DT${h}H${m}M${s}S`;
  }

  public static dateToLocalString(date: Date) {
    return date.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

}
