import { ShopTransactionDataModel } from './ShopTransactionDataModel';
import i18n from '@/i18n';

export enum ShopTransactionTrigger {
  PARTICIPATION_ACCEPTED = "participation_accepted",
  PARTICIPATION_CANCELLED = "participation_cancelled",
  PARTICIPATION_CHANGED = "participation_changed",
  TIME_SLOT_CHANGED = "time_slot_changed",
  HOURLY_RATE_CHANGED = "hourly_rate_changed",
  PRODUCT_PURCHASED = "product_purchased",
  MANUAL_ADJUSTMENT = "manual_adjustment",
  ORDER_CANCELLED = "order_cancelled",
}

export class ShopTransactionModel {

  public createdAt: Date | null = null;
  public balance: number;
  public pointsDiff: number;
  public trigger: ShopTransactionTrigger;
  public triggerData: ShopTransactionDataModel;

  constructor({
    createdAt = null,
    balance = 0,
    pointsDiff = 0,
    trigger = ShopTransactionTrigger.PARTICIPATION_ACCEPTED,
    triggerData = new ShopTransactionDataModel(),
  }: {
    createdAt?: Date | null,
    balance?: number,
    pointsDiff?: number,
    trigger?: ShopTransactionTrigger,
    triggerData?: ShopTransactionDataModel
  } = {}) {
    this.createdAt = createdAt;
    this.balance = balance;
    this.pointsDiff = pointsDiff;
    this.trigger = trigger;

    if (triggerData instanceof ShopTransactionDataModel) {
      this.triggerData = triggerData;
    } else {
      this.triggerData = new ShopTransactionDataModel(triggerData);
    }
    this.triggerData.trigger = trigger;
  }

  public get triggerActionName() {
    return i18n.t(this.trigger);
  }
}
