import axios, { AxiosResponse, AxiosInstance} from 'axios';
import { OrganizerAPI } from '@/apis';
import { EventModel } from '@/models/EventModel';

export class EventAssignerService {

    private static internal: EventAssignerService = new EventAssignerService(OrganizerAPI.instance);

    public static get instance(): EventAssignerService {
      return EventAssignerService.internal;
    }

    constructor(private api: AxiosInstance) { }

    public requestAIAssignments(eventId: number, body: any): Promise<any> {
        return this.api.post<any>(
            `/events/${eventId}/auto_assign/`,
            body,
        ).then((response: AxiosResponse<any>) => {
            return response.data;
        });
    }
}
