export class RewardCategoryModel {

  public id: number | null;
  public name: string;
  public hourlyRate: string;
  public icon: string;
  public isDefault: boolean;
  public timeSlotColor: string;

  constructor({
    id = null,
    name = '',
    hourlyRate = '',
    icon = '',
    isDefault = false,
    timeSlotColor = '',
  }: {
    id?: number | null,
    name?: string,
    hourlyRate?: string,
    icon?: string,
    isDefault?: boolean,
    timeSlotColor?: string,

  } = {}) {
    this.id = id;
    this.name = name;
    this.hourlyRate = hourlyRate;
    this.icon = icon;
    this.isDefault = isDefault;
    this.timeSlotColor = timeSlotColor;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      hourlyRate: this.hourlyRate,
      icon: this.icon,
      isDefault: this.isDefault,
      timeSlotColor: this.timeSlotColor,
    };
  }

  static getRewardCategoryFromId(id: number, categories: RewardCategoryModel[]): RewardCategoryModel | null {
    const categ = categories.find((c) => Number(c.id) === Number(id));
    return categ ? categ : null;
  }

  static getDefaultRewardCategory(categories: RewardCategoryModel[]): RewardCategoryModel | null {
    const defaultReward = categories.find((c) => c.isDefault);
    return defaultReward ? defaultReward : null;
  }

}

