import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI } from '@/apis';
import { EventModel } from '@/models/EventModel';
import { ManagerModel } from '@/models/ManagerModel';


export class ManagerService {

  private static internal: ManagerService = new ManagerService(OrganizerAPI.instance);

  public static get instance(): ManagerService {
    return ManagerService.internal;
  }

  constructor(private api: AxiosInstance) { }

  public retrieveManagers(orgId: number): Promise<ManagerModel[]> {
    return this.api.get<ManagerModel[]>('/admin_manager/', {
      params: {
        orgId,
      },
    }).then((response: AxiosResponse<ManagerModel[]>) => {
      return response.data.map((d: any): ManagerModel => new ManagerModel(d));
    });
  }

  public retrieveManager(managerId: number): Promise<ManagerModel> {
    return this.api.get<ManagerModel>(`/admin_manager/${managerId}/`)
      .then((response: AxiosResponse<ManagerModel>) => {
        return new ManagerModel(response.data);
      });
  }

  public createManager(manager: ManagerModel): Promise<ManagerModel> {
    return this.api.post<ManagerModel>(
      `/admin_manager/`,
      manager.toJSON()).then((response: AxiosResponse<ManagerModel>) => {
        return new ManagerModel(response.data);
      });
  }

  public updateManager(manager: ManagerModel): Promise<ManagerModel> {
    return this.api.put<ManagerModel>(
      `/admin_manager/${manager.id}/`,
      manager.toJSON()).then((response: AxiosResponse<ManagerModel>) => {
        return new ManagerModel(response.data);
      });
  }

  public deleteManager(manager: ManagerModel): Promise<ManagerModel> {
    return this.api.delete(`/admin_manager/${manager.id}/`).then((response: AxiosResponse) => {
      return manager;
    });
  }

}
