import { render, staticRenderFns } from "./App.vue?vue&type=template&id=cf212dea&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&id=cf212dea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../nix/store/4gqm246hzdfzp7056w08y59vnj1ww375-node-dependencies-ehro-admin-4.5.2/lib/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports