export enum CampaignFieldType {
  Text = 'text',
  Date = 'date',
  DateRange = 'date_range',
  Choice = 'choice',
}

import { DateUtils } from '@/utils/date';
import * as Utils from '@/assets/js/Utils.js';
import { EventModel } from './EventModel';
import { DateTime } from 'luxon';

export class CampaignFieldModel {

  public static copy(c: CampaignFieldModel | undefined): CampaignFieldModel {
    if (c === undefined || c === null) { return new CampaignFieldModel(); }

    const cp = new CampaignFieldModel();
    cp.id = c.id;
    cp.campaignId = c.campaignId;
    cp.label = c.label;
    cp.description = c.description;
    cp.minChoices = c.minChoices;
    cp.maxChoices = c.maxChoices;
    cp.fieldType = c.fieldType;
    cp.order = c.order;
    c.options.forEach((opt) => cp.options.push(opt));
    return cp;
  }

  public id: number | null = null;
  public campaignId: string | null = null;
  public label: string;
  public description: string;
  public minChoices: number | null;
  public maxChoices: number | null;
  public fieldType: string;
  public options: any[];
  public order: number;
  public timeZone: string = '';
  public userAnswer: any[] = [];


  constructor({
    id = null,
    campaignId = null,
    label = '',
    description = '',
    minChoices = null,
    maxChoices = null,
    fieldType = '',
    timeZone = 'Europe/Zurich',
    options = [],
    order = 0,
    isMandatory = false,
  }: {
    id?: number | null,
    campaignId?: string | null,
    label?: string,
    description?: string,
    minChoices?: number | null,
    maxChoices?: number | null,
    fieldType?: string,
    options?: any[],
    order?: number,
    timeZone?: string,
    isMandatory?: boolean,
  } = {}) {
    this.id = id;
    this.campaignId = campaignId;
    this.label = label;
    this.description = description;
    this.minChoices = minChoices;
    this.maxChoices = maxChoices;
    this.fieldType = fieldType;
    this.options = options;
    this.order = order;
    this.timeZone = timeZone;
    if (isMandatory && this.fieldType === CampaignFieldType.Text) {
      this.minChoices = this.maxChoices = 1;
    }

    if (this.fieldType === CampaignFieldType.DateRange) {
      this.options.forEach((o) => {
        if (o.startsAt) {
          o.startsAt = DateTime.fromJSDate(o.startsAt).setZone(timeZone);
          o.startDate = o.startsAt.toFormat('dd.LL.yyyy');
          o.editableStartDate = o.startsAt.toFormat('yyyy-LL-dd');
          o.startTime = o.startsAt.toFormat('HH:mm');
        }
        if (o.endsAt) {
          o.endsAt = DateTime.fromJSDate(o.endsAt).setZone(timeZone);
          o.endDate = o.endsAt.toFormat('dd.LL.yyyy');
          o.editableEndDate = o.endsAt.toFormat('yyyy-LL-dd');
          o.endTime = o.endsAt.toFormat('HH:mm');
        }
      });
    }
  }

  public toJSON(): any {
    const JSON = {} as any;
    if (this.id) {
      JSON.id = this.id;
    }
    if (this.campaignId) {
      JSON.campaignId = this.campaignId;
    }
    JSON.label = this.label;
    JSON.description = this.description;
    JSON.minChoices = this.minChoices;
    JSON.maxChoices = this.maxChoices;
    JSON.fieldType = this.fieldType;

    JSON.options = this.options;

    if (JSON.minChoices === '') {
      JSON.minChoices = null;
    }

    if (JSON.maxChoices === '') {
      JSON.maxChoices = null;
    }

    if (this.fieldType === CampaignFieldType.Text) {
      JSON.isMandatory = (Number(this.minChoices) === 1);
    } else if (this.fieldType === CampaignFieldType.Date) {
      JSON.options = this.options.map((o) => {
        const startDate: Date = new Date(o.date);
        return { id: o.id, date: DateUtils.dateToISODate(startDate) };
      });
    }
    return JSON;
  }

  // For dateRange only
  public update() {
    if (this.fieldType === CampaignFieldType.DateRange) {
      this.options.map((o) => {
        o.startsAt = DateTime.fromFormat(o.editableStartDate + ' ' + o.startTime, 'yyyy-MM-dd hh:mm', { zone: this.timeZone });
        o.endsAt = DateTime.fromFormat(o.editableEndDate + ' ' + o.endTime, 'yyyy-MM-dd hh:mm', { zone: this.timeZone });

        o.startDate = o.startsAt.toFormat('dd.LL.yyyy');
        o.editableStartDate = o.startsAt.toFormat('yyyy-LL-dd');
        o.startTime = o.startsAt.toFormat('HH:mm');

        o.endDate = o.endsAt.toFormat('dd.LL.yyyy');
        o.editableEndDate = o.endsAt.toFormat('yyyy-LL-dd');
        o.endTime = o.endsAt.toFormat('HH:mm');

      });
    }
  }

  public getOptionsForHTML(event: EventModel | null = null) {
    if (this.fieldType === CampaignFieldType.DateRange) {
      return this.options.map((o) => {

        let str = o.startDate;
        if (o.startDate === o.endDate) {
          str += ' ' + o.startTime + ' - ' + o.endTime;
        } else {
          str += ' ' + o.startTime + ' à ' + o.endTime + ' le ' + o.endDate;
        }
        return { text: str, value: o };
      });
    } else if (this.fieldType === CampaignFieldType.Date) {
      return this.options.map((o) => {
        const startDate: Date = new Date(o.date);
        const strStartDate = startDate.toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        o.label = strStartDate;
        return { text: strStartDate, value: o };
      });
    } else {

      return this.options.map((o) => {
        return {
          text: o.label,
          value: o,
        };
      });
    }
  }

  public getOptionsForHTMLFromatted(event: EventModel | null = null) {
    return this.getOptionsForHTML(event).map((o) => {
      return {
        text: o.text.replace(/ *\([^)]*\) */g, ''),
        value: o.value,
      };
    });
  }

  public getOptionById(oid: number): string | undefined {
    const res = this.getOptionsForHTML(null).find((o) => o.value.id === oid);
    if (res) {
      return res.text;
    }
    return this.options.find((o) => o.id === oid).label;
  }




}
