import { DateTime, Interval } from 'luxon';
import { ShopProductModel } from './ShopProductModel';

export enum OrderStatus {
  AwaitingDelivery = "awaiting_delivery",
  Delivered = "delivered",
  Cancelled = "cancelled",
}

export class ShopOrderModel {
  public id: number | null;
  public productName: string;
  public quantity: number;
  public vouchersCodes: string[];
  public product: ShopProductModel;
  public createdAt: Date | null = null;
  public status: OrderStatus;

  constructor({
    id = null,
    productName = '',
    quantity = 0,
    vouchersCodes = [],
    product = new ShopProductModel(),
    createdAt = null,
    status = OrderStatus.AwaitingDelivery,
  }: {
    id?: number | null,
    productName?: string,
    quantity?: number,
    vouchersCodes?: string[],
    product?: ShopProductModel,
    createdAt?: Date | null,
    status?: OrderStatus,
  } = {}) {
    this.id = id;
    this.productName = productName;
    this.quantity = quantity;
    this.vouchersCodes = vouchersCodes;
    this.product = product;
    this.createdAt = createdAt;
    this.status = status;
  }

  isCancelled() {
    return this.status == OrderStatus.Cancelled;
  }

  isDelivered() {
    return this.status == OrderStatus.Delivered;
  }
}
