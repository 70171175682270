import { EventModel } from './EventModel';

export class ManagerModel {

  public static copy(mng: ManagerModel): ManagerModel {
    const manager = new ManagerModel();
    manager.id = mng.id;
    manager.email = mng.email;
    manager.password = mng.password;
    manager.organization = mng.organization;
    manager.eventIds = mng.eventIds;
    return manager;
  }


  public id: number | null = null;
  public email: string;
  public password: string;
  public organization: number | null = null;
  public eventIds: number[];

  constructor({
    id = null,
    organization = null,
    email = '',
    password = '',
    eventIds = [],
  }: {
    id?: number | null,
    organization?: number | null,
    email?: string,
    password?: string,
    eventIds?: number[],
  } = {}) {
    this.id = id;
    this.organization = organization;
    this.email = email;
    this.password = password;
    this.eventIds = eventIds;
  }

  public toJSON(): any {
    return {
      id: this.id,
      email: this.email,
      organization: this.organization,
      password: this.password,
      eventIds: this.eventIds,
    };
  }
}
