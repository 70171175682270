import { OrganizationModel } from '@/models/OrganizationModel';
import { AuthenticationService } from '@/services';
import { AxiosResponse } from 'axios';

export class UserModel {

  public email: string;
  public firstName: string;
  public lastName: string;
  public token: string;
  public isSuperUser: boolean;
  public isStaff: boolean;
  public language: string;
  public dfaId: string;
  public activeOrganization: number;
  public availableOrganizations: OrganizationModel[] = [];

  constructor({
    email = '',
    firstName = '',
    lastName = '',
    token = '',
    isSuperUser = false,
    isStaff = false,
    activeOrganization = -1,
    availableOrganizations = [],
    language = '',
    dfaId = '',
  } = {}) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.token = token;
    this.isSuperUser = isSuperUser;
    this.isStaff = isStaff;
    this.activeOrganization = activeOrganization;
    this.availableOrganizations = availableOrganizations.map((d: any): OrganizationModel => {
      let org;
      if (d instanceof OrganizationModel) {
        org = d;
      } else {
        org = new OrganizationModel(d);
      }
      return org;
    });
    this.language = language;
    this.dfaId = dfaId;
  }

  public toJSON(): any {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      token: this.token,
      isSuperUser: this.isSuperUser,
      isStaff: this.isStaff,
      language: this.language,
      dfaId: this.dfaId,
      // activeOrganization: this.activeOrganization,
      // availableOrganizations: this.availableOrganizations,
    };
  }




  private _waitForRefresh = false;

  public get currentOrganization(): OrganizationModel | null {
    const org = this.availableOrganizations.find((org) => {
      return org.id === this.activeOrganization;
    });

    if (org === undefined) {
      if (!this._waitForRefresh) {
        this._waitForRefresh = true;
        AuthenticationService.instance.getOrganizations(this).then((organization: OrganizationModel | undefined) => {
          this._waitForRefresh = false;
          return organization;
        });
      }

      return null;
    }
    return org;
  }

}
