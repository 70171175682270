import { DateTime, Interval } from 'luxon';
import { ShopTransactionTrigger } from './ShopTransactionModel';

export class ShopTransactionDataModel {

  public trigger: string = '';
  public eventName: string;
  public positionName: string;
  public startTime: DateTime;
  public endTime: DateTime;

  public eventId: number | null = null;
  public positionId: number | null = null;
  public participationId: number | null = null;

  public productName: string | null = null;
  public quantity: number | null = null;

  public reason: string | null = null;

  constructor({
    eventName = '',
    positionName = '',
    eventId = null,
    positionId = null,
    participationId = null,
    startTime = DateTime.fromMillis(0),
    endTime = DateTime.fromMillis(0),
    productName = null,
    quantity = null,
    reason = null,
  }: {
    eventName?: string,
    positionName?: string,
    eventId?: number | null,
    positionId?: number | null,
    participationId?: number | null,
    startTime?: DateTime,
    endTime?: DateTime,
    productName?: string | null,
    quantity?: number | null,
    reason?: string | null,
  } = {}) {
    this.eventName = eventName;
    this.positionName = positionName;
    this.startTime = startTime;
    this.endTime = endTime;
    this.eventId = eventId;
    this.positionId = positionId;
    this.participationId = participationId;
    this.productName = productName;
    this.quantity = quantity;
    this.reason = reason;
  }

  public get detail() {
    if (this.trigger === ShopTransactionTrigger.PRODUCT_PURCHASED || this.trigger === ShopTransactionTrigger.ORDER_CANCELLED) {
      return `<strong>${this.quantity}x</strong> ${this.productName}`;
    } else if (this.trigger === ShopTransactionTrigger.MANUAL_ADJUSTMENT) {
      return `<strong>${this.reason}</strong>`;
    } else {
      return `<strong>${this.eventName}</strong> / ${this.positionName}`;
    }
  }
}
