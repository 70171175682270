export class ParticipationsRankingModel {
  public id: number;
  public name: string;

  constructor({
    id,
    name,
  }: {
    id: number,
    name: string,
  }) {
    this.id = id;
    this.name = name;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
